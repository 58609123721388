<template>
  <v-container>
    <v-row>
      <app-bar></app-bar>
    </v-row>
    <v-row>
      <v-col>
        <h1>Choose your subscription</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card @click="individualClicked">
          <v-card-title> Individual </v-card-title>
          <v-card-subtitle>$100/month</v-card-subtitle>
          <v-card-text>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Id, itaque similique blanditiis,
            nobis earum ipsa, mollitia ex odit laboriosam cum fuga corporis vitae a voluptatibus minus
            voluptate molestias pariatur deserunt.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title> Small/Medium Business </v-card-title>
          <v-card-subtitle>$90/month/user</v-card-subtitle>
          <v-card-text>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Id, itaque similique blanditiis,
            nobis earum ipsa, mollitia ex odit laboriosam cum fuga corporis vitae a voluptatibus minus
            voluptate molestias pariatur deserunt.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title> Enterprise </v-card-title>
          <v-card-subtitle>$80/month/user</v-card-subtitle>
          <v-card-text>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsam ratione esse accusamus quia
            dignissimos ipsum tempore, dolore quibusdam facilis assumenda qui, doloremque nemo, eos
            itaque. Sunt, iste. Id, maiores voluptatibus.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text> Today you pay: Pay $100 /month/user from 2022-01-14 </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Credit Card Details</v-card-title>
          <v-form ref="loginForm" v-model="isFormValid" @submit.prevent="paymentClicked">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="userCreditCardNumber" label="Credit Card Number">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="userCreditCardExpiry" label="Expiry Date"> </v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field v-model="userCreditCardSecurity" label="Secruity Code"> </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn color="orange" type="submit"> Start Subscription </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, defineComponent } from "@vue/composition-api";
import AppBar from "@/components/AppBar.vue";

export default defineComponent({
  components: { AppBar },

  setup(props, { root }) {
    const isFormValid = true;
    const processingPayment = ref(false);
    const userCreditCardNumber = ref("");
    const userCreditCardExpiry = ref("");
    const userCreditCardSecurity = ref("");

    const individualClicked = () => {
      console.log("Individiual");
    };

    const paymentClicked = async () => {
      console.log("Payment Clicked");

      processingPayment.value = true;

      root.$router.push({ name: "Dashboard" });
    };

    return {
      isFormValid,
      processingPayment,
      userCreditCardNumber,
      userCreditCardSecurity,
      userCreditCardExpiry,
      individualClicked,
      paymentClicked
    };
  }
});
</script>

<style lang="scss" scoped>
</style>